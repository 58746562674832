<template>
  <div class="w-sm-100 w-xs-100">
    <vs-row class="lg-trading-content">

      <vs-row vs-type="flex" vs-w="12" vs-justify="center" class="trading-notification-width" v-if="withdrawDetails">
        <div class="trading-flex-column w-100 text-center white-card-lg mt-5 mb-5">

          <div class="mt-2">
            <img src="/assets/trading-assets/tick-icon.svg" alt="bank-logo" class="lg-logo"/>
          </div>

          <div class="mb-2 mt-2">
            <div class="text-success">You have withdrawn</div>
            <vs-row vs-type="flex" vs-justify="center" vs-align="center">
              <h3 class="fw-700">SGD {{formatFiatPrice(this.withdrawDetails.wdrw_amt)}}</h3>
            </vs-row>
            <div class="mb-1 text-medium">from your withdrawable balance</div>
          </div>

          <div class="trading-border-top-dashed">
            <vs-row vs-type="flex" vs-w="12" vs-justify="space-between" vs-align="center" class="p-2">
              <vs-col vs-w="12" vs-type="flex" vs-justify="flex-start">
                <div class="text-small">{{formatDateForHistoryContent(this.withdrawDetails.wdrw_dt)}}</div>
              </vs-col>
<!--              <vs-col vs-w="6" vs-type="flex" vs-justify="flex-end">-->
<!--               <div class="text-small">09.15 am</div>-->
<!--              </vs-col>-->
            </vs-row>
          </div>

<!--          <div class="trading-border-top-solid">-->
<!--            <vs-row vs-type="flex" vs-w="12" vs-justify="space-between" vs-align="center" class="p-2">-->
<!--              <vs-col vs-w="6" vs-type="flex" vs-justify="flex-start">-->
<!--                <div class="text-small">STATUS</div>-->
<!--              </vs-col>-->
<!--              <vs-col vs-w="6" vs-type="flex" vs-justify="flex-end">-->
<!--                <div class="text-small">COMPLETED</div>-->
<!--              </vs-col>-->
<!--            </vs-row>-->
<!--          </div>-->

          <div class="trading-border-top-solid">
            <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">

              <vs-col vs-w="6" vs-type="flex" vs-justify="flex-start">
               <div class="text-small">TRANSFER TO</div>
              </vs-col>
              <vs-col vs-w="6" vs-type="flex" vs-justify="flex-end">
                <div  class="text-small">{{this.withdrawDetails.wdrw_bank.bank_name}}</div>
              </vs-col>

            </vs-row>
          </div>

          <div class="trading-border-top-solid">
            <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">
              <vs-col vs-w="6" vs-type="flex" vs-justify="flex-start">
                <div class="text-small">ACCOUNT NO</div>
              </vs-col>
              <vs-col vs-w="6" vs-type="flex" vs-justify="flex-end">
                <div class="text-small">{{this.withdrawDetails.wdrw_bank.bank_acct_num}}</div>
              </vs-col>

            </vs-row>
          </div>

          <div class="trading-border-top-solid">
            <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">

              <vs-col vs-w="6" vs-type="flex" vs-justify="flex-start">
                <div class="text-small">REFERENCE NO</div>
              </vs-col>
              <vs-col vs-w="6" vs-type="flex" vs-justify="flex-end">
                <div class="text-small">{{this.withdrawDetails.wdrw_ref_num}}</div>
              </vs-col>
            </vs-row>
          </div>

<!--          <div class="trading-notification-border-highlighted">-->
<!--            <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">-->
<!--              <div class="trading-flex-column">-->
<!--                <div class="text-left">-->
<!--                  <div class="text-success text-small">CURRENT BALANCE</div>-->
<!--                </div>-->
<!--                <div class="text-left text-small">-->
<!--                SGD 0.00-->
<!--                </div>-->
<!--              </div>-->
<!--            </vs-row>-->
<!--          </div>-->
        </div>
        <button class="btn-trading-success w-100" @click="backToHome()">Back to home</button>
      </vs-row>

    </vs-row>
  </div>
</template>
<script>

import { CheckIcon } from 'vue-feather-icons'
import {mapGetters} from "vuex";

export default {
  name: 'withdraw-to-bank-account-notification-page',
  components: {
      // CheckIcon
  },
  data() {
    return {
      mode: process.env.VUE_APP_STAGING_ENV ? null : "production",

      withdrawDetails : null,

      debugConsole : false,

    }
  },
  watch: {
    $route() {

    },
  },
  computed: {
    ...mapGetters(["userWithdrawBankAccountDetailGetter"]),


  },
  metaInfo: {
    title: 'TransCrypt - Transfer Form | Send Money Faster with Cryptocurrency',
  },
  mounted() {
   if(this.debugConsole) console.log("params on buying page", this.$route.params);




    if(this.debugConsole) console.log("Get the withdraw user details in the withdraw notification detail", this.userWithdrawBankAccountDetailGetter);

    this.withdrawDetails = this.userWithdrawBankAccountDetailGetter && this.userWithdrawBankAccountDetailGetter;



  },

  methods: {

    backToHome(){
      this.$router.push('/trading/main');
    }
  }
}

</script>
<style>


</style>